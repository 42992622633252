import { Swiper, SwiperSlide } from 'swiper/react'
import s from '../../Home.module.css'
import cn from 'classnames'
import { Picture, Link } from '@components/ui'
import { refLink } from '../../utils'
import Arrow from '../Arrow'

/**
 * CustomCard component represents a custom card section on the home page.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.section - The section data for the card.
 * @param {string} props.locale - The locale for the card.
 * @param {Object} props.shopCommon - The common shop data for the card.
 * @param {Array} props.relatedProducts - The related products data for the card.
 * @returns {JSX.Element} The rendered CustomCard component.
 */
const CustomCard = ({ section, locale, shopCommon, relatedProducts }) => {
  const settings = {
    spaceBetween: 8,
    slidesPerView: section.slidesPerView || 'auto',
    breakpoints: section.breakpoints || {
      1024: {
        allowTouchMove: false,
        slidesPerView: section.lists?.length || 3,
      },
      1920: {
        allowTouchMove: false,
        slidesPerView: 'auto',
      },
    },
  }
  return (
    <div
      className={cn('layer overflow-hidden', {
        'min-l:hidden': section.hide_pc,
      })}
      style={{
        background: section.background,
      }}
    >
      <div className="content">
        <div
          className="pb-[var(--pb)] pt-[var(--pt)] min-l:pb-[var(--pb-l)] min-l:pt-[var(--pt-l)] min-xl:pb-[var(--pb-xl)] min-xl:pt-[var(--pt-xl)]"
          style={{
            '--pt': section.paddingTop || '20px',
            '--pb': section.paddingBottom || '20px',
            '--pt-l': section.paddingTop_l || '40px',
            '--pb-l': section.paddingBottom_l || '40px',
            '--pt-xl': section.paddingTop_xl || '60px',
            '--pb-xl': section.paddingBottom_xl || '60px',
          }}
        >
          <div className={cn('min-l:text-center')}>
            <h2
              className={cn(s.title, 'min-l:text-center')}
              dangerouslySetInnerHTML={{ __html: section.title }}
            ></h2>
          </div>
          {section.lists && (
            <Swiper
              {...settings}
              className={cn('mt-6 !overflow-visible  min-l:mt-8 min-xl:mt-12', {
                [s.customBox]: section.lists.length === 1,
                [s.aniBox]: section.lists.length > 1,
                // [s.swiper2box]: section.lists.length === 2,
                // [s.swiper1box]: section.lists.length === 1,
              })}
            >
              {section.lists?.map((item, index) => {
                let {
                  title,
                  image_xl,
                  image,
                  image_mob,
                  image_hover,
                  learn_more,
                  buy_now,
                  learn_more_text,
                } = item
                if (image && !image_mob) {
                  image_mob = image
                }
                if (image_mob && !image) {
                  image = image_mob
                }
                if (learn_more && buy_now) {
                  learn_more = refLink(
                    learn_more,
                    section.title,
                    'learn_' + (index + 1)
                  )
                  buy_now = refLink(
                    buy_now,
                    section.title,
                    'buynow_' + (index + 1)
                  )
                } else {
                  if (learn_more !== undefined) {
                    learn_more = refLink(learn_more, section.title, index + 1)
                  }
                  if (buy_now !== undefined) {
                    buy_now = refLink(buy_now, section.title, index + 1)
                  }
                }
                return (
                  <SwiperSlide
                    key={index}
                    className={cn(
                      // s.swiperSlide,
                      s.hoverShow,
                      {
                        // [s.hoverShow]:
                        //   section.hover_show && section.lists.length > 1,
                        [s.imageHover]: image_hover,
                        /* [s.swiperSlideWidth_l]:
                          section.width_l ||
                          [1, 2].includes(section.lists.length),
                        [s.swiperSlideWidth_xl]:
                          section.width_xl ||
                          [1, 2].includes(section.lists.length), */
                      },
                      'relative !h-auto overflow-hidden rounded-xl l:!w-[298px]',
                      {
                        'min-xxl:!w-[916px]': section.lists.length === 2,
                        'min-xxl:!w-[608px]': section.lists.length === 3,
                        'bg-white': section.position === 'bottom',
                      }
                    )}
                    /* style={{
                      '--width': section.width || '298px',
                      '--width_l': section.width_l || '422px',
                      '--width_xl': section.width_xl || '422px',
                      '--width_xxl': section.width_xxl || '584px',
                    }} */
                  >
                    <Link href={learn_more}>
                      <Picture
                        source={`${
                          image_xl || image
                        }, ${image} 1920, ${image_mob} 1024`}
                        alt={title}
                        className={cn('transition', s.oriImg, {
                          'h-full': section.position !== 'bottom',
                        })}
                        imgClassName="h-full !object-cover !object-center"
                        imgWidth={832}
                        imgHeight={1036}
                      />
                    </Link>
                    <Picture
                      source="https://cdn.shopify.com/s/files/1/0521/9411/5753/files/custom_card_shape.png?v=1723540018, https://cdn.shopify.com/s/files/1/0521/9411/5753/files/custom_card_shape_mob.png?v=1723540017 1024"
                      className={cn(
                        'absolute bottom-0 left-0 w-full transition min-l:translate-y-full',
                        s.shape
                      )}
                      imgClassName="h-[212px]"
                    />
                    {/* <ShapeMob
                      className={cn(
                        'absolute bottom-0 left-0 w-full transition min-l:translate-y-full min-xl:hidden',
                        s.shape,
                        s.shapeMob
                      )}
                    />
                    {section.lists?.length === 3 && (
                      <ShapePC
                        className={cn(
                          'absolute bottom-0 left-0 w-full transition min-l:translate-y-full',
                          s.shape
                        )}
                      />
                    )}
                    {section.lists?.length === 2 && (
                      <ShapePCL
                        className={cn(
                          'absolute bottom-0 left-0 w-full transition min-l:translate-y-full',
                          s.shape
                        )}
                      />
                    )} */}

                    <div
                      className={cn(
                        s.textBox,
                        'absolute bottom-0 left-0 box-border w-full px-[18px] py-6 l:text-white min-xl:p-8'
                      )}
                    >
                      <Link
                        className={cn(
                          'nounderline flex items-center gap-[2px] font-semibold'
                        )}
                        href={learn_more}
                      >
                        <h4
                          className={cn(
                            'line-clamp-1 text-lg font-semibold leading-[1.2] min-l:text-base min-l:text-[#333] min-xl:text-[20px] min-xxl:text-2xl'
                          )}
                          style={{
                            fontSize: section.fontSize,
                            color: item.pc_text,
                          }}
                          dangerouslySetInnerHTML={{ __html: title }}
                        ></h4>
                      </Link>
                      {item.subtitle && (
                        <p
                          title={item.subtitle}
                          className={cn(
                            'mt-3 line-clamp-2 h-[40px] text-sm font-medium min-l:opacity-0 min-xl:h-[48px] min-xl:text-base'
                          )}
                          dangerouslySetInnerHTML={{ __html: item.subtitle }}
                        ></p>
                      )}
                      <div className={cn('mt-3')}>
                        <Link
                          className={cn(
                            'flex items-center gap-[2px] font-semibold text-brand-color',
                            {
                              'justify-center': [
                                'center top',
                                'top center',
                              ].includes(section.position),
                            }
                          )}
                          style={{
                            color: section.textColor,
                          }}
                          href={learn_more}
                        >
                          <span>
                            {learn_more_text ||
                              section.learn_more ||
                              shopCommon.learn_more}
                          </span>
                          <Arrow />
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          )}
        </div>
      </div>
    </div>
  )
}

export default CustomCard
